import { Card } from "primereact/card";
import Digraph from "../compenents/digraph";
import { observer } from "mobx-react-lite";
import { useContext, useRef, useState } from "react";
import storeContext from "../store/woord-context";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SelectTaal from "../compenents/select-taal";
import { Taal } from "../model/taal";

const OpvoerenWoord = observer(() => {

  const store = useContext(storeContext);
  const toast = useRef<Toast>(null);
  const digraphRef = useRef<AutoComplete>(null);

  const [ nederlandsWoord, setNederlandsWoord ] = useState<string|undefined>();

  const opslaan = () => {
    store.opslaan();
    setNederlandsWoord(store.opvoerNL);
    toast.current?.show({ severity: 'info', content: 'Vertaling opgeslagen'});
    digraphRef.current?.focus();
  }

  const sortTitel = (a: string| undefined, b: string | undefined): number =>{
    if (!a || !b) {
      return 0;
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const opvoerLijst = () =>{
    const lijst = store.vertalingen.slice().sort((a,b) => sortTitel(a?.nl, b?.nl));
    let index = lijst.findIndex(v => v.nl == nederlandsWoord);
    if (index == -1){
      // setRowIndex(index);
      return lijst.slice(0, 10);
    }
    index = Math.max(index - 4, 0);
    // setRowIndex(index);
    return lijst.slice(index, index + 10);
  }

  const disabled = !store.opvoerNL || !store.opvoerAnders;

  const getTaalLabel = ()=>{
    const entry = Object.entries(Taal).find(e => e[1] == store.currentTaal);
    if (entry &&entry.length > 1) {
      return entry[0];
    }
    return "";
  }

  return <Card title="Opvoeren vertaling">
    <Toast ref={toast} />
    <div className="grid">
    <div className="col">
    <div className="field">
            <SelectTaal className="mr-2" id="boekTaal" value={store.currentTaal} onChange={(e:any) => store.selecteerTaal(e.target.value)} />
      <Dropdown options={store.boekSecties} placeholder="Selecteer een boek en sectie" optionValue="id" optionLabel="label" value={store.currentBoekSectieId} onChange={(e:any) => { store.selectBoekSectieId(e.target.value) }} />
    </div>
    {store.currentBoekSectieId !== null &&
    <>
    <div className="field">
      <Digraph ref={digraphRef} value={store.opvoerNL} id="nl" label="Nederlands" onChange={(e:any) => store.opvoerNL = e.target.value} />
    </div>
    <div className="field">
      <Digraph value={store.opvoerAnders} id="duits" label={getTaalLabel()} onChange={(e:any) => store.opvoerAnders = e.target.value} />
    </div>
    <div className="field">
      <Button icon="pi pi-save" raised label="Opslaan" disabled={disabled} onClick={opslaan} />
    </div>
    </>
    }
    </div>
    <div className="col">
      <DataTable
          value={opvoerLijst()}
      >
        <Column header="Nederlands" field="nl" />
        <Column header="Anders" field="anders" />
      </DataTable>
    </div>
    </div>
  </Card>
});

export default OpvoerenWoord;

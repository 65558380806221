/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from "mobx";
import SchoolApi from "../api/school-api";
import { Boek } from "../model/boek";
import { BoekSectie } from "../model/boek-sectie";
import { Taal } from "../model/taal";

class BoekStore {
    private _currentTaal: Taal = Taal.Duits;
    private _currentBoek: Boek|null = null;
    private _currentBoekSectie: BoekSectie = {};
    private _boekSecties: any[] = [];
    private _api: SchoolApi;
    private _rerender: boolean = false;
    private _opvoerNL: string = "";
    private _boekPagina: string = "";
    private _boekSectie: string = "";
    private _opvoerAnders: string = "";
    private _boeken: Boek[] = [];

    constructor(api: SchoolApi) {
        this._currentTaal = Taal.Duits;
        this._currentBoek = null;
        this._currentBoekSectie = {};
        this._boekSecties = [];
        this._boeken = [];
        this._api = api;
        this._boekPagina = "";
        this._boekSectie = "";
        this._rerender = true;
        this._opvoerAnders = "";
        this._opvoerNL = "";
        makeAutoObservable(this);
        this.init();
    }

    public get currentTaal (): Taal {
        return this._currentTaal;
    }

    public set currentTaal (value: Taal) {
        runInAction(() => {
            this._currentTaal = value;
        });
    }

    public get boekSecties () {
        return this._boekSecties;
    }

    public set boekSecties (value) {
        runInAction(() => {
            this._boekSecties = value;
        })
    }

    public get currentBoekSectie () {
        return this._currentBoekSectie;
    }

    public set currentBoekSectie (value) {
        runInAction(() => {
            this._currentBoekSectie = value;
        });
    }

    public get opvoerNL () {
        return this._opvoerNL;
    }

    public set opvoerNL (value) {
        runInAction(() => {
            this._opvoerNL = value;
        })
    }

    public get currentBoek (): Boek|null {
        return this._currentBoek;
    }

    public set currentBoek (value: Boek|null) {
        runInAction(() => {
            this._currentBoek = value;
        });
    }

    public get boekPagina () {
        return this._boekPagina;
    }

    public set boekPagina (value) {
        runInAction(() => {
            this._boekPagina = value;
        })
    }

    public get boekSectie () {
        return this._boekSectie;
    }

    public set boekSectie (value) {
        runInAction(() => {
            this._boekSectie = value;
        })
    }

    public get opvoerAnders () {
        return this._opvoerAnders;
    }

    public set opvoerAnders (value) {
        runInAction(() => {
            this._opvoerAnders = value;
        })
    }

    public get rerender () {
        return this._rerender;
    }

    public set rerender (value) {
        runInAction(() => {
            this._rerender = value;
        });
    }

    public get boeken () {
        return this._boeken;
    }

    public set boeken (value) {
        runInAction(() => {
          this._boeken = value;
        });
    }

    public init = async () => {
        this.currentTaal = Taal.Duits;
        await this.getBoekenByTaal();
    }

    private getBoekenByTaal = async () =>{
        this.boeken = await this._api.getBoekenByTaal(this.currentTaal);
        if (!this.boeken || this.boeken.length == 0) {
            return;
        }
        this.boeken.forEach(async (boek: Boek) => {
            boek.secties = await this._api.getBoekSectiesByBoek(boek.id);
        })
    }

    // private getBoekSectiesByBoek = async (boek: Boek) =>{
        // this.boekSecties = (await this._api.getBoekSectiesByBoek(this.currentBoek.id))
              // .sort((a: any,b: any) => { return a.bladzijde - b.bladzijde})
              // .map((r: any) => { return { id: r.id, label: `${r.boek.taal} - ${r.boek.titel}: ${r.bladzijde} - ${r.sectie}`}})
              // ;
        // this.currentBoekSectie = this.boekSecties.find(f => f);
    // }

    public opslaanBoek = async () => {
        console.log('Opslaan boek', this.currentBoek?.taal, this.currentBoek?.titel);
        const res = await this._api.opslaanBoek(this.currentBoek);
        this.boeken = res;
        this.currentBoek = null;
        this.boeken.forEach(async (boek: Boek) => {
            boek.secties = await this._api.getBoekSectiesByBoek(boek.id);
        })
    }

    public opslaanBoekSectie = async () => {
        console.log('Opslaan boek sectie', this.currentBoekSectie?.id, this.currentBoekSectie.bladzijde, this.currentBoekSectie.sectie);
        let res = await this._api.opslaanBoekSectie(this.currentBoekSectie);
        this.boeken = res;
        this.currentBoek = null;
    }
}

export default BoekStore;

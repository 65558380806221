import { BoekSectie } from "./boek-sectie";

export class Boek {
  id: number|null;
  titel?: string;
  taal?: string;
  secties?: BoekSectie[];

  constructor () {
    this.id = null
  }
}

import { Dropdown } from "primereact/dropdown"
import { Taal } from "../model/taal";

const talen = [
  { name: "-", code: null},
  { name: "Duits", code: Taal.Duits},
  { name: "Engels", code: Taal.Engels},
  { name: "Frans", code: Taal.Frans},
  { name: "Latijn", code: Taal.Latijn},
  { name: "Nederlands", code: Taal.Nederlands},
];

const SelectTaal = (props: any) => {
  return <Dropdown { ...props } onChange={props.onChange} options={talen} optionLabel="name" optionValue="code">
  </Dropdown>
}

export default SelectTaal;

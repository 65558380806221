import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import storeContext from "../store/testen-context";
import { observer} from "mobx-react-lite";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import Digraph from "../compenents/digraph";
import { ProgressBar } from "primereact/progressbar";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Panel } from "primereact/panel";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import SelectTaal from "../compenents/select-taal";
import { Taal } from "../model/taal";

const Testen = observer(() => {

  const store = useContext(storeContext);
  const [ controleTekst, setControleTekst ] = useState<string>("");
  const digraphRef = useRef<AutoComplete>(null);

  useEffect(() => {
    digraphRef.current?.focus();
  }, [store.teTestenVertaling]);

  const check = (id: any) => {
    store.checkResultaat(id, controleTekst);
    digraphRef.current?.focus();
    if (store.teTestenVertaling?.resultaat == 0) {
      setControleTekst("");
    }
    setControleTekst("");
  }

  const resultaatTemplate = (data:any) => {
    switch (data.resultaat) {
      case 0: return <Tag icon="pi pi-question" severity="secondary"/>;
      case -1: return <Tag icon="pi pi-thumbs-down" severity="danger" className="mr-4" />;
      case 1: return <Tag icon="pi pi-thumbs-up" severity="success" />;
      }
  }

  const reset = ()=>{
    store.resetTest();
    setControleTekst("");
  }

  const footer = (
    <React.Fragment>
      <ProgressBar value={Math.round(100 * store.teTestenVertalingen.filter(v => v.resultaat == 1).length / store.teTestenVertalingen.length)} />
        <ul>
          <li>Totaal aantal woordjes {store.teTestenVertalingen.length}</li>
          <li>Totaal aantal geteste woordjes {store.teTestenVertalingen.filter(v => v.resultaat != 0).length}</li>
          <li>Totaal aantal goede woordjes {store.teTestenVertalingen.filter(v => v.resultaat == 1).length}</li>
          <li>Totaal aantal foute woordjes {store.teTestenVertalingen.filter(v => v.resultaat == -1).length}</li>
        </ul>
        <Button icon="pi pi-undo" size="small" rounded text raised onClick={reset} />
    </React.Fragment>
  );

  const getTaalLabel = ()=>{
    const entry = Object.entries(Taal).find(e => e[1] == store.currentTaal);
    if (entry &&entry.length > 1) {
      return entry[0];
    }
    return "";
  }

  return (
    <Splitter className="flex">
      <SplitterPanel className="flex-grow-1" size={33}>
        <Panel className="flex-grow-1" header="Testen woordjes">
          <div className="field">
            <SelectTaal className="mr-2" id="boekTaal" value={store.currentTaal} onChange={(e:any) => store.selecteerTaal(e.target.value)} />
            <Dropdown options={store.boekSecties} placeholder="Selecteer een boek en sectie" optionValue="id" optionLabel="label" value={store.currentBoekSectieId} onChange={(e:any) => { store.selectBoekSectieId(e.target.value) }} />
          </div>
          {store.teTestenVertaling !== null &&
          <>
          <div className="field">
            <div className="p-inputtext">{store.teTestenVertaling.nl}</div>
          </div>
          <div className="field">
            <Digraph value={controleTekst} onChange={(e:any) => setControleTekst(e.target.value)} ref={digraphRef} id="duits" label={getTaalLabel()} />
          </div>
          <div className="field">
            <Button icon="pi pi-check" size="small" rounded text raised onClick={() => {check(store.teTestenVertaling?.id)}}/>
          </div>
          </>
          }
        </Panel>
      </SplitterPanel>
      <SplitterPanel className="flex-grow-1" size={67}>
        <Splitter className="flex" layout="vertical">
          <SplitterPanel className="flex-grow-1">
            <DataTable alwaysShowPaginator={false} emptyMessage="-" className="flex-grow-1" value={store.teTestenVertalingen.filter(r =>r.resultaat == -1)} paginator rows={5}>
              <Column sortable field="nl" header="Nederlands"/>
              <Column field="anders" header="Vertaling" />
              <Column field="controleTekst" header="Fout" />
              <Column body={resultaatTemplate} />
            </DataTable>
          </SplitterPanel>
          <SplitterPanel className="flex-grow-1">
            <DataTable alwaysShowPaginator={false} emptyMessage="-" className="flex-grow-1" value={store.teTestenVertalingen.filter(r =>r.resultaat !== -1)} paginator rows={5} footer={footer}>
              <Column sortable field="nl" header="Nederlands"/>
            </DataTable>
          </SplitterPanel>
        </Splitter>
      </SplitterPanel>
    </Splitter>
  )
});

export default Testen

import axios from "axios";
import { Boek } from "../model/boek";
import { Taal } from "../model/taal";
import { BoekSectie } from "../model/boek-sectie";

export default class SchoolApi {

    constructor () {};

    async getVertalingenByBoekSectieId (id: number|null|undefined)  {
        return await axios.get(`./api/vertaling/${id}`)
        .then(res => {
            return res.data;
        });
    }

    async getBoeken ()  {
        const res = await axios.get("./api/boeken");
        return res.data;
    }

    async getBoekenByTaal (taal: Taal)  {
        return await axios.get(`./api/boeken/${taal}`)
        .then(res => {
            return res.data;
        });
    }

    async getBoekSectiesByTaal (taal: Taal)  {
        return await axios.get(`./api/boeken/secties/taal/${taal}`)
        .then(res => {
            return res.data;
        });
    }

    async getBoekSecties ()  {
        return await axios.get("./api/boeken/secties/alle")
        .then(res => {
            return res.data;
        });
    }

    async getBoekSectiesByBoek (boekId: number|null)  {
        return await axios.get(`./api/boeken/secties/id/${boekId}`)
        .then(res => {
            return res.data;
        });
    }

    async opslaan (nl: string, anders: string, boekSectieId: number|null)  {
        console.log("Opslaan api", nl, anders);
        return await axios.post("./api/vertaling/save/d", { nl, anders, boekSectieId })
        .then(res => {
            return res.data;
        });
    }

    async opslaanBoek (boek: Boek|null)  {
        if (boek == null) {
            return null;
        }
        console.log("Opslaan boek", boek.taal, boek.titel);
        let res = await axios.post("./api/boeken/opslaan", { id: boek.id, titel: boek.titel, taal: boek.taal });
        return res.data;
  }

    async opslaanBoekSectie (boekSectie: BoekSectie)  {
        console.log("Opslaan boek sectie", boekSectie.id, boekSectie.bladzijde, boekSectie.sectie);
        let res = await axios.post("./api/boeken/sectie/opslaan", boekSectie);
        return res.data;
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from "mobx";
import SchoolApi from "../api/school-api";
import { Vertaling } from "../model/vertaling";
import { Taal } from "../model/taal";
import { Boek } from "../model/boek";

class TestenStore {
    private _currentTaal: Taal = Taal.Duits;
    private _currentBoekSectieId: number|null = null;
    private _vertalingen: Vertaling[] = [];
    private _teTestenVertaling: Vertaling|null;
    private _teTestenVertalingen: Vertaling[] = [];
    private _boekSecties: any[] = [];
    private _api: SchoolApi;

    constructor(api: SchoolApi) {
        this._currentTaal = Taal.Duits;
        this._currentBoekSectieId = null;
        this._vertalingen = [];
        this._boekSecties = [];
        this._teTestenVertaling = null;
        this._teTestenVertalingen = [];
        this._api = api;
        makeAutoObservable(this);
        this.init();
    }

    public get teTestenVertaling (): Vertaling|null {
        return this._teTestenVertaling;
    }

    public set teTestenVertaling (value: Vertaling|null) {
        runInAction(() => {
            this._teTestenVertaling = value;
        });
    }

    public get currentTaal (): Taal {
        return this._currentTaal;
    }

    public set currentTaal (value: Taal) {
        runInAction(() => {
            this._currentTaal = value;
        });
    }

    public get teTestenVertalingen (): Vertaling[] {
        return this._teTestenVertalingen;
    }

    public set teTestenVertalingen (value: Vertaling[]) {
        runInAction(() => {
            this._teTestenVertalingen = value;
        });
    }

    public get boekSecties () {
        return this._boekSecties;
    }

    public set boekSecties (value) {
        runInAction(() => {
            this._boekSecties = value;
        })
    }

    public get currentBoekSectieId () {
        return this._currentBoekSectieId;
    }

    public set currentBoekSectieId (value) {
        runInAction(() => {
            this._currentBoekSectieId = value;
            this.teTestenVertalingen = this.vertalingen.filter(v => v.boekSectie?.id == value) ?? [];
            this.teTestenVertaling = this.vertalingen.find(v => v.boekSectie?.id == value) ?? null;
        });
    }

    public get vertalingen () {
        return this._vertalingen;
    }

    public get vertalingenTeTesten () {
        return this._vertalingen.filter(v => v.resultaat !== 1 && v.boekSectie?.id === this._currentBoekSectieId);
    }

    public set vertalingen (value) {
        runInAction(() => {
          this._vertalingen = value;
        });
    }

    public init = async () => {
        this.currentTaal = Taal.Duits;
        await this.getBoekSectiesByTaal();
    }

    private getBoekSectiesByTaal = async () =>{
        const res = await this._api.getBoekSectiesByTaal(this.currentTaal);
        const boeken = await this._api.getBoeken();
        this.boekSecties = res
              .sort((a: any,b: any) => { return a.bladzijde - b.bladzijde})
              .map((r: any) => {
                const boek = boeken.find((b: Boek) => b.id = r.boekId);
                return { id: r.id, label: `${boek.taal} - ${boek.titel}: ${r.bladzijde} - ${r.sectie}`}})
              ;
        if (!this.boekSecties || this.boekSecties.length == 0) {
            this.currentBoekSectieId = null;
            this.vertalingen = [];
            this.teTestenVertalingen = [];
            this.teTestenVertaling = null;
            return;
        }
        this.currentBoekSectieId = this.boekSecties[0].id;
        await this.getVertalingenByBoekSectieId();
    }

    private getVertalingenByBoekSectieId = async ()=>{
        this.vertalingen = await this._api.getVertalingenByBoekSectieId(this._currentBoekSectieId);
        this.vertalingen = this.vertalingen.map((r:any) => { return { ...r, resultaat: 0, controleTekst: ""}});
        this.teTestenVertaling = this.vertalingen.find(v => v) ?? null;
        this.teTestenVertalingen = this.vertalingen ?? [];
    }

    public resetTest = () =>{
        this.vertalingen.forEach(v => v.resultaat = 0);
        this.vertalingen = this.vertalingen.slice();
        this.teTestenVertaling = this.vertalingen.find(v => v) ?? null;
        this.teTestenVertalingen = this.vertalingen ?? [];
    }

    public checkResultaat = (id: number, controleTekst: string) => {
        runInAction(() => {
            const vertaling = this.teTestenVertalingen.find(v => v.id == id) ?? new Vertaling();
            vertaling.controleTekst = controleTekst;
            if (vertaling.anders === controleTekst) {
                vertaling.resultaat = 1;
            } else {
                vertaling.resultaat = -1;
            }
            this.vertalingen = this.vertalingen.slice();
            this.teTestenVertalingen = this.teTestenVertalingen.slice();
            const stillToCheck = this.teTestenVertalingen.filter(v => v.resultaat !== 1);
            this.teTestenVertaling = stillToCheck[Math.floor(stillToCheck.length* Math.random()| 0)] ?? null;
        });
    }

    public selectBoekSectieId = async (id: number) =>{
        this.currentBoekSectieId = id;
        await this.getVertalingenByBoekSectieId();
    }

    public selecteerTaal = async (taal: Taal) => {
        this.currentTaal = taal;
        await this.getBoekSectiesByTaal();
    }
}

export default TestenStore;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from "mobx";
import SchoolApi from "../api/school-api";
import { Vertaling } from "../model/vertaling";
import { Taal } from "../model/taal";
import { Boek } from "../model/boek";

class WoordStore {
    private _currentTaal: Taal = Taal.Duits;
    private _currentBoekSectieId: number | null = null;
    private _vertalingen: Vertaling[] = [];
    private _boekSecties: any[] = [];
    private _api: SchoolApi;
    private _opvoerNL: string = "";
    private _opvoerAnders: string = "";

    constructor(api: SchoolApi) {
        this._currentTaal = Taal.Duits;
        this._currentBoekSectieId = null;
        this._vertalingen = [];
        this._boekSecties = [];
        this._api = api;
        this._opvoerAnders = "";
        this._opvoerNL = "";
        makeAutoObservable(this);
        this.init();
    }

    public get currentTaal (): Taal {
        return this._currentTaal;
    }

    public set currentTaal (value: Taal) {
        runInAction(() => {
            this._currentTaal = value;
        });
    }

    public get boekSecties () {
        return this._boekSecties;
    }

    public set boekSecties (value) {
        runInAction(() => {
            this._boekSecties = value;
        })
    }

    public get currentBoekSectieId () {
        return this._currentBoekSectieId;
    }

    public set currentBoekSectieId (value) {
        runInAction(() => {
            this._currentBoekSectieId = value;
        });
    }

    public get opvoerNL () {
        return this._opvoerNL;
    }

    public set opvoerNL (value) {
        runInAction(() => {
            this._opvoerNL = value;
        })
    }

    public get opvoerAnders () {
        return this._opvoerAnders;
    }

    public set opvoerAnders (value) {
        runInAction(() => {
            this._opvoerAnders = value;
        })
    }

    public get vertalingen () {
        return this._vertalingen;
    }

    public set vertalingen (value) {
        runInAction(() => {
          this._vertalingen = value;
        });
    }

    public init = async () => {
        this.currentTaal = Taal.Duits;
        await this.getBoekSectiesByTaal();
    }

    private getBoekSectiesByTaal = async () =>{
        const res = await this._api.getBoekSectiesByTaal(this.currentTaal);
        const boeken = await this._api.getBoeken();
        this.boekSecties = res
              .sort((a: any,b: any) => { return a.bladzijde - b.bladzijde})
              .map((r: any) => {
                const boek = boeken.find((b: Boek) => b.id = r.boekId);
                return { id: r.id, label: `${boek.taal} - ${boek.titel}: ${r.bladzijde} - ${r.sectie}`}})
              ;
        if (!this.boekSecties || this.boekSecties.length == 0) {
            this.currentBoekSectieId = null;
            this.vertalingen = [];
            this.opvoerAnders = "";
            this.opvoerNL = "";
            return;
        }
        this.currentBoekSectieId = this.boekSecties[0].id;
        await this.getVertalingenByBoekSectieId();
    }

    private getVertalingenByBoekSectieId = async ()=>{
        this.vertalingen = await this._api.getVertalingenByBoekSectieId(this._currentBoekSectieId);
        this.vertalingen = this.vertalingen.map((r:any) => { return { ...r, resultaat: 0, controleTekst: ""}});
    }

    public opslaan = () => {
        console.log('Opslaan store', this.opvoerNL, this.opvoerAnders, this.currentBoekSectieId);
        this._api.opslaan(this.opvoerNL, this.opvoerAnders, this.currentBoekSectieId)
        .then(res => {
            this.vertalingen = res.map((r: any) => { return { ...r, resultaat: 0, controleTekst: ""}});
            this.opvoerNL = "";
            this.opvoerAnders = "";
        })
    }

    public selectBoekSectieId = async (id: number) =>{
        this.currentBoekSectieId = id;
        await this.getVertalingenByBoekSectieId();
    }

    public selecteerTaal = async (taal: Taal) => {
        this.currentTaal = taal;
        await this.getBoekSectiesByTaal();
    }
}

export default WoordStore;

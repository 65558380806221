import { observer } from "mobx-react-lite";
import React, { useContext, useRef, useState } from "react";
import storeContext from "../store/boek-context";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import SelectTaal from "../compenents/select-taal";
import { Boek } from "../model/boek";
import { InputNumber } from "primereact/inputnumber";

const OpvoerenBoek = observer(() => {

  const store = useContext(storeContext);
  const toast = useRef<Toast>(null);
  const [ expandedRows, setExpandedRows ] = useState<DataTableExpandedRows|DataTableValueArray|undefined>(undefined);

  const opslaanBoek = async () => {
    await store.opslaanBoek();
    toast.current?.show({ severity: 'info', content: 'Boek opgeslagen'});
    setBoekopvoeren(false);
  }

  const opslaanBoekSectie = async () => {
    await store.opslaanBoekSectie();
    toast.current?.show({ severity: 'info', content: 'Sectie opgeslagen'});
    setBoekSectieopvoeren(false);
  }

  const boekOpvoerenAanzetten = () => {
    store.currentBoek = new Boek();
    setBoekopvoeren(true);
  }
  const headerBoek = () => {
    return <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" >Boeken</span>
      <Button className="pi pi-plus" rounded raised onClick={boekOpvoerenAanzetten}></Button>
    </div>
  }

  const headerBoekSectie = (data: any) => {
    return <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" >Boek sectie</span>
      <Button className="pi pi-plus" rounded raised onClick={() => { store.currentBoek = data; setBoekSectieopvoeren(true); }}></Button>
    </div>
  }

  const [ boekOpvoeren, setBoekopvoeren ] = useState<boolean>(false);
  const [ boekSectieOpvoeren, setBoekSectieopvoeren ] = useState<boolean>(false);

  const taalTemplate = (data: any) => {
    return <SelectTaal disabled value={data.taal} />;
  }

  const allowExpansion = (_rowData: any) => {
    return true; // rowData.sectie?.length >= 0;
  }

  const rowExpansionTemplate = (data: any) => {
    return <DataTable
        value={data.secties}
        header={() => headerBoekSectie(data)}>
      <Column field="bladzijde" header="Bladzijde" />
      <Column field="sectie" header="Sectie" />
      <Column body={editBoekSectie} />
    </DataTable>
  }

  const editBoek = (rowData: any) => {
    return <>
        <Button icon="pi pi-pencil" className="mr-2" onClick={() => {
          store.currentBoek = rowData;
          setBoekopvoeren(true);
        }}/>
        <Button icon="pi pi-trash" severity="danger" onClick={() => {
          store.currentBoek = rowData;
          setBoekopvoeren(true);
        }}/>
        </>
  }

  const editBoekSectie = (rowData: any) => {
    return <>
        <Button icon="pi pi-pencil" className="mr-2" onClick={() => {
          store.currentBoekSectie = rowData;
          setBoekSectieopvoeren(true);
        }}/>
        <Button icon="pi pi-trash" severity="danger" onClick={() => {
          store.currentBoekSectie = rowData;
          setBoekSectieopvoeren(true);
        }}/>
        </>
  }

  return <React.Fragment>
    <Toast ref={toast} />
    <DataTable
        value={store.boeken}
        header={headerBoek}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}>
      <Column expander={allowExpansion} style={{ width: "5em" }} />
      <Column field="titel" header="Titel" />
      <Column body={taalTemplate} header="Taal" />
      <Column body={editBoek} />
    </DataTable>
    <Dialog style={{ minWidth: 500 }} header="Boek" visible={boekOpvoeren} onHide={() => { setBoekopvoeren(false)}}>
      <div className="field grid">
        <label className="col-3" htmlFor="boekTitel">Titel</label>
        <div className="col">
          <InputText id="boekTitel" value={store.currentBoek?.titel || "" } onChange={(e:any) => store.currentBoek = { ...store.currentBoek, id: store.currentBoek?.id ?? null, titel: e.target.value }} />
        </div>
      </div>
      <div className="field grid">
        <label className="col-3" htmlFor="boekTaal">Taal</label>
        <div className="col">
          <SelectTaal id="boekTaal" value={store.currentBoek?.taal} onChange={(e:any) => store.currentBoek = { ...store.currentBoek, id: store.currentBoek?.id ?? null, taal: e.target.value}} />
        </div>
      </div>
      <div className="field grid">
        <div className="col">
          <Button icon="pi pi-save" raised label="Opslaan" disabled={!store.currentBoek?.titel || !store.currentBoek.taal} onClick={opslaanBoek} />
        </div>
      </div>
    </Dialog>
    <Dialog style={{ minWidth: 500 }} header="Boek sectie" visible={boekSectieOpvoeren} onHide={() => { setBoekSectieopvoeren(false)}}>
      <div className="field grid">
        <label htmlFor="boekPagina" className="col-4">Pagina</label>
        <div className="col">
          <InputNumber value={store.currentBoekSectie.bladzijde} id="boekPagina" onChange={(e:any) => store.currentBoekSectie.bladzijde = e.target.value} />
        </div>
      </div>
      <div className="field grid">
        <label htmlFor="boekSectie" className="col-4">Hoofdstuk</label>
        <div className="col">
          <InputText id="boekSectie" value={store.currentBoekSectie.sectie} onChange={(e:any) => store.currentBoekSectie.sectie = e.target.value} />
        </div>
      </div>
      <div className="field grid">
        <div className="col">
          <Button icon="pi pi-save" raised label="Opslaan" disabled={!store.currentBoekSectie.bladzijde || !store.currentBoekSectie.sectie} onClick={opslaanBoekSectie} />
        </div>
      </div>
    </Dialog>
  </React.Fragment>
});

export default OpvoerenBoek;

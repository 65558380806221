import { BoekSectie } from "./boek-sectie";

export class Vertaling {
  id?: number;
  nl?: string = "Hahaha";
  anders?: string;
  boekSectie?: BoekSectie;
  resultaat?: number;
  controleTekst?: string;

  constructor () {
  }
}